var bumpIt = function() {  
      $('body').css('margin-bottom', $('.footer').height());
    },
    didResize = false;

bumpIt();

$(window).resize(function() {
  didResize = true;
});
setInterval(function() {  
  if(didResize) {
    didResize = false;
    bumpIt();
  }
}, 250);

// whcookies.js
function WHCreateCookie(name, value, days) {
    var date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    var expires = "; expires=" + date.toGMTString();
	document.cookie = name+"="+value+expires+"; path=/";
}
function WHReadCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i=0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

window.onload = WHCheckCookies;

function WHCheckCookies() {
    if(WHReadCookie('cookies_accepted') != 'T') {
        var message_container = document.createElement('div');
        message_container.id = 'cookies-message-container';
        var html_code = '<div id="cookies-message" style="padding: 7px 10px; font-size: 14px; line-height: 22px; text-align: center; position: fixed; bottom: 0px; background-color: #fff; width: 100%; z-index: 9999; border-top:1px solid #eee;">Nasza strona używa ciasteczek. Korzystając ze strony akceptujesz politykę plików cookies. <a href="http://wszystkoociasteczkach.pl" rel="nofollow">Dowiedz się więcej</a> <a href="javascript:WHCloseCookiesWindow();" id="accept-cookies-checkbox" name="accept-cookies" style="background-color: #fff; padding: 5px 10px; color: #6b8ca4; font-weight:600; border-radius: 0px; -moz-border-radius: 0px; -webkit-border-radius: 0px; display: inline-block; margin-left: 10px; text-decoration: none; cursor: pointer; border: 2px solid #89a3b6; text-transform:uppercase">Rozumiem</a></div>';
        message_container.innerHTML = html_code;
        document.body.appendChild(message_container);
    }
}

function WHCloseCookiesWindow() {
    WHCreateCookie('cookies_accepted', 'T', 365);
    document.getElementById('cookies-message-container').removeChild(document.getElementById('cookies-message'));
}